 import fc from "./imgs/fc.jpg"
 import sp from "./imgs/sp.jpeg"
 import rd from "./imgs/rd.jpg"
 import { db } from "./firebase"

 import "./rewardsc.css"
import { collection, getDoc, onSnapshot, query } from "firebase/firestore"
import { useEffect, useState } from "react"
import { getDocs } from "firebase/firestore/lite"
import Home from "./Home"
import spin from "./imgs/spinner.gif"

  function Rewards(){
    const [red,set_red]= useState(0);

    const [gq_info ,set_gq_info]= useState();
    const [loading ,set_loading]=useState(false)
    const [test,set_test] = useState()
    const gq_ref= collection(db,"gqdata");


    useEffect(()=>{

        var info = [];

        const get_data= async ()=>{
            const q=  query(gq_ref);
            const snap =  onSnapshot(gq_ref,(snapee)=>{
                const datae=  snapee.docs.map((doc)=>({...doc.data()}));
                setTimeout(()=>{
                    
                    set_gq_info(datae[0]["games"])
                    set_loading(1)
                    set_test(datae[0]["games"][1]["url"])
    
                },500)
            })
        };
        get_data();
    },[])


    function show_key(keys,num){
        const key = document.getElementsByClassName(keys)[num];
        if (num != 0){
          document.getElementsByClassName("v_r")[num-1].style.display="none"

        }
        key.textContent=gq_info[num]["gk"];
    }


    function redr(page){
        set_red(1);
       }
       if(red ==1){
         return (<Home></Home>)
       }
    return loading ? (<div>

        <div id="r_main">
    

            <div id="first_">
                
             <div id="first_l" style={{backgroundImage:"url("+"https://image.api.playstation.com/vulcan/ap/rnd/202206/0719/qpAUFYXSVRlSN0Z1MSKXPu92.jpg" +")"}}>
             <div id="top">
              <div id="top_l" >
                <h1 id="Site_name">Aioonc</h1>
              </div>
              <div id="top_r">
                <div id="top_r_c">
                  <div  id="home_but" onClick={()=>{redr("./Home")}} >
                    <img src="https://cdn-icons-png.flaticon.com/512/1946/1946436.png"></img>
                    <span>Home</span>
                  </div>


              
                  

                </div>
              </div>

            </div>
            {/* top */}
                 <div id="first_l_c">
                    <h1 id="fgn">{gq_info[0]["name"]}</h1>
                    <h3>Experience the emotional storytelling and unforgettable characters in The Last of Us™, winner of over 200 Game of the Year awards.</h3>
                    <div id="pric_c">
                    <button id="f_p_b"  onClick={()=>{show_key("key",0)}}>Get It for Free</button>
                       </div>
                {/*pric_c */}
                <h3 className="key" style={{color:"white"}}></h3>


                   </div> 
            {/*first_l_c */}
            </div>
          {/*first_l */}

              </div>
               {/*first */}

               



               <div id="sec">
                <div id="re_box">
                <img src={gq_info[1]["url"]} alt="Image description" /> 
                <span style={{color:"#40C4FF" , margin:"5px",marginBottom:"0px"}}>{gq_info[1]["type"]}</span>   
                <div  id="s_n_r">
                <h2>{gq_info[1]["name"]}</h2>

                <div>
                  <h3 id="g_r">{gq_info[1]["rate"]}</h3>
                  <img src="https://cdn-icons-png.flaticon.com/512/1828/1828884.png"></img>
                </div>

                  </div>     

 
                  <div id="s_n_r">
                    <h3>Platform:</h3>
                    <h3>{gq_info[1]["platform"]}</h3>
                    </div>          
                 <h3 className="key" style={{color:"black"}}></h3>

                    <button  className="v_r" onClick={()=>{show_key("key",1)}}>Get It for Free</button>
                </div>
               {/* re_box */}
               <div id="re_box">
                    <img src={gq_info[2]["url"]}></img>
                     <span style={{color:"#40C4FF" , margin:"5px",marginBottom:"0px"}}>{gq_info[2]["type"]}</span>   
                <div  id="s_n_r">
                <h2>{gq_info[2]["name"]}</h2>

                <div>
                  <h3 id="g_r">{gq_info[2]["rate"]}</h3>
                  <img src="https://cdn-icons-png.flaticon.com/512/1828/1828884.png"></img>
                </div>

                  </div>     

 
                  <div id="s_n_r">
                    <h3>Platform:</h3>
                    <h3>{gq_info[2]["platform"]}</h3>
                    </div>          

                    
                    <h3 className="key" style={{color:"black"}}></h3>


                    <button  className="v_r" onClick={()=>{show_key("key",2)}}>Get It for Free</button>
                </div>
               {/* re_box */}
               <div id="re_box">
                    <img src={gq_info[3]["url"]}></img>
                    <span style={{color:"#40C4FF" , margin:"5px",marginBottom:"0px"}}>{gq_info[3]["type"]}</span>   
                <div  id="s_n_r">
                <h2>{gq_info[3]["name"]}</h2>

                <div>
                  <h3 id="g_r">{gq_info[3]["rate"]}</h3>
                  <img src="https://cdn-icons-png.flaticon.com/512/1828/1828884.png"></img>
                </div>

                  </div>     

 
                  <div id="s_n_r">
                    <h3>Platform:</h3>
                    <h3>{gq_info[3]["platform"]}</h3>
                    </div>          



                    <h3 className="key" style={{color:"black"}}></h3>

                    <button className="v_r" onClick={()=>{show_key("key",3)}}>Get It for Free</button>
                </div>
               {/* re_box */}


               <div id="re_box">
                    <img src={gq_info[4]["url"]}></img>
                    <span style={{color:"#40C4FF" , margin:"5px",marginBottom:"0px"}}>{gq_info[4]["type"]}</span>   
                <div  id="s_n_r">
                <h2>{gq_info[4]["name"]}</h2>

                <div>
                  <h3 id="g_r">{gq_info[4]["rate"]}</h3>
                  <img src="https://cdn-icons-png.flaticon.com/512/1828/1828884.png"></img>
                </div>

                  </div>     

 
                  <div id="s_n_r">
                    <h3>Platform:</h3>
                    <h3>{gq_info[4]["platform"]}</h3>
                    </div>          
                  
                    <h3 className="key" style={{color:"black"}}></h3>

                    <button   className="v_r" onClick={()=>{show_key("key",4)}}>Get It for Free</button>
                </div>
               {/* re_box */}



               </div>
               {/* sec */}

        </div>
    </div>) : (<div style={{textAlign:"center" , backgroundColor:"white" , height:"100vh"}}>

<img src={spin} style={{width : "100px"}}></img>
<h1>Getting Your experience ready</h1>
</div>);
}

export default Rewards