
import "./Homec.css"
import React, { useEffect, useState } from 'react';
import {formatDateToString} from "./formatDateToString"; 
import sunrise_img from "./imgs/sunrise (1).png"
import sunset_img from "./imgs/sunset.png"
import humdity_img from "./imgs/humidity (1).png"

import { formatTimestampToTime } from "./formatTimestampToTime";
import { list } from "firebase/storage";
import { formatweek } from "./formatweek";
import { Weather_icon } from "./Weather_icon";
import {sorn } from "./sorn";
import spin from "./imgs/spinner.gif"



import Rewards from "./Rewards";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "./firebase";
import Rain from "./Rain";
import { Filter_tz } from "./Filter_tz";
import Terms from "./Terms"
function Home(){
  const [red,set_red]= useState(0);
    const [citye,set_citye]= useState("london");
    const  url="https://api.openweathermap.org/data/2.5/forecast?q="+extractCity()+"&appid=d3c8ea544742efa7e8d96171711fb797&units=metric";
    var data = new Array();
    const [time_, set_time]= useState();

    const [city, set_city]= useState([]);
    const [main_, set_main]= useState([]);
    const [data_,set_data]= useState([]);
    const [loding ,set_loading]= useState(0);
    const [week,set_week]= useState([]);
    const [rise,set_rise]= useState();
    const [tz,set_tz]= useState();
    
    const [set,set_set]= useState();
    const [imgs_, set_imgs_] = useState([])
    const [nimgs_, set_nimgs_] = useState([])
    const [count, set_count]= useState(0);


    const gimgs = collection(db,"Imagesw");



    function extractCity() {
      // Split the string by the forward slash "/"
      const tz= Intl.DateTimeFormat().resolvedOptions();
      const tzs= tz["timeZone"];
      const parts = tzs.split('/');
      
      // If there are at least two parts, return the second part (assuming the city is after the slash)
      if (parts.length >= 2) {
        return parts[1];
      } else {
        // Handle cases where the input string doesn't follow the expected format
        return 'City not found'; // Or provide a more informative message
      }
    }

     function to_number( strNumber){
        return Math.floor(Number(strNumber));
     }

     function img_cycle(img,num){
     
      setTimeout(() =>  {
       
       if(img.length >0){
        set_nimgs_(img[num]);
        const n= num+1;
        set_count(n);
        if(n == img.length ){
          set_count(0);
          img_cycle(img,0);

        }
        else{
          img_cycle(img,n);

        }
       }
        

      }, 10000);
     }

    useEffect(()=>{

      
      const get_i_d = async ()=>{
       const snap =  onSnapshot(gimgs,(snapee)=>{
        const datae = snapee.docs.map((doc)=>({...doc.data()}));
        setTimeout(()=>{
            
           set_imgs_(datae[0]["imgs"]);
           set_nimgs_(datae[0]["imgs"][0])
           img_cycle(datae[0]["imgs"],0);

        },500)
       })
      }
      get_i_d();









        const test = async ()=>{

            try {
              await  fetch(url)
            .then((res)=>res.json())
            .then((body)=>{
              data =body["list"];
              var indx = new Array();

              for(var i=1;i< data.length;i++ ){
                const dateObj = new Date(data[i]["dt"] * 1000); // Convert to milliseconds
                if(dateObj.getHours() == 15 && data[0]["dt"] != data[i]["dt"] ){
                  if(indx.length <=3){
                    indx[indx.length]=i;
                  }
                }
              }
              set_week(indx);
              set_data(data)
              
              set_city(body["city"]);
              set_rise(Filter_tz(body["city"]["sunrise"],body["city"]["timezone"]))
              set_tz(body["city"]["timezone"])
              set_set(Filter_tz(body["city"]["sunset"],body["city"]["timezone"]))


                set_main(data[0]["main"]);
                var test= data[1]["dt"];
                if(test >1 ){
                  set_loading(1);
                  
                }
               
                set_time(formatDateToString(data[0]["dt_txt"]));
        
        })
            } catch (error) {
                console.log(error);
            }
            
        }
        test()
     
       
    },[])

    const call_weather= async ()=>{
      const  url="https://api.openweathermap.org/data/2.5/forecast?q="+citye+"&appid=d3c8ea544742efa7e8d96171711fb797&units=metric";
      const test = async ()=>{

        try {
          await  fetch(url)
        .then((res)=>res.json())
        .then((body)=>{
          data =body["list"];
          var indx = new Array();

          for(var i=1;i< data.length;i++ ){
            const dateObj = new Date(data[i]["dt"] * 1000); // Convert to milliseconds
            if(dateObj.getHours() == 15 && data[0]["dt"] != data[i]["dt"] ){
              if(indx.length <=3){
                indx[indx.length]=i;
              }
            }
          }
          set_week(indx);
          set_data(data)
          
          set_city(body["city"]);
          set_rise(Filter_tz(body["city"]["sunrise"],body["city"]["timezone"]))
          set_tz(body["city"]["timezone"])
          set_set(Filter_tz(body["city"]["sunset"],body["city"]["timezone"]))


            set_main(data[0]["main"]);
            var test= data[1]["dt"];
            if(test >1 ){
              set_loading(1);
              
            }
           
            set_time(formatDateToString(data[0]["dt_txt"]));
    
    })
        } catch (error) {
            console.log(error);
        }
        
    }
    test()


    }

    if (window.location.pathname == "/Rewards"){
      return (<Rewards></Rewards>)
    }
    
    if (window.location.pathname == "/Terms"){
      return (<Terms></Terms>)
    }

    function redr(page){
     set_red(page);
    }
    if(red ==1){
      return (<Rewards></Rewards>)
    }
     if(red ==2){
       return (<Terms></Terms>)
     }
    return loding ? (<div>
      
        <div id="main" style={{backgroundImage:"url("+nimgs_["url"] +")"}}>
            <div id="top">
              <div id="top_l" >
                <h1 id="Site_name">Aioonc</h1>
              </div>
              <div id="top_r">
                <div id="top_r_c">
                  <div  id="home_but" >
                    <img src="https://cdn-icons-png.flaticon.com/512/1946/1946488.png"></img>
                    <span>Home</span>
                  </div>
                  



                  <div id="rew_but"  onClick={()=>{redr(1)}}>
                    <img src="https://cdn-icons-png.flaticon.com/512/4737/4737610.png"></img>
                    <span >Rewards</span>
                  </div>



                  <div id="rew_but"  >
                    <img src="https://cdn-icons-png.flaticon.com/512/8344/8344913.png"></img>
                    <a href={nimgs_["url"]} target="_blank">{nimgs_["name"]}</a>
                  </div>


                  <div id="ser_but" onClick={()=>{document.getElementById("left").style.display= "flex"}} >
                    <img src="https://cdn-icons-png.flaticon.com/512/3031/3031293.png"></img>
                  </div>

                  

                </div>
              </div>

            </div>
            <div id="mid">
                <div id="left">
                    <h1  style={{margin:"1px",fontSize:"40px", }}>Welcome </h1>
                    <h1 id="welcom" style={{margin:"1px",fontSize:"70px",color:"yallow" }}>traveler</h1>

                    <h3 style={{fontSize:"40px" , margin:"5px"}}>New Era of</h3>
                    <h1  style={{margin:"10px",fontSize:"40px", }}> Entertainment  </h1>

                    <input type="text" onKeyDown={(e)=>{if(e.key == 'Enter'){call_weather()}}} className="ce" placeholder="Enter your city" onChange={(e)=>{set_citye(e.target.value)}} ></input>
                    <button id="search" onClick={()=>{call_weather()}}>Search</button>
                  
                    
                </div>


                <div id="right">
                    <h1>{city["name"]}</h1>

                    <div id="r_one">

                     <div id="r_one_r">


                      <div id="w_n">
                          <div style={{display:"flex", flexDirection:"column"}}><h1 id="temp">{  to_number(main_["temp"] )}°</h1>
                         <h1 id="date_"  style={{marginBottom:"4px"}}>{time_}</h1>
                            </div>
                      
                         <div id="w_n_o">
                        {Weather_icon(data_[0]["weather"][0]["main"],sorn(data_[0]["dt"],rise,set))}
                        <h1 id="w_ty">{data_[0]["weather"][0]["description"]}</h1>
                        </div>
                      </div>
                     

                          <div id="forecast">
                           <h2 style={{color:"black", textAlign:"center",margin:"0",marginTop:"8px"}}>Weather Today</h2>

                          <div id="w_t">
                              <div className="w_t_s">
                              {Weather_icon(data_[0]["weather"][0]["main"],sorn(data_[0]["dt"],rise,set))}
                                <h2 className="w_t_s_t">{formatTimestampToTime(Filter_tz(data_[0]["dt"],tz))}</h2>
                                {Rain(to_number(data_[0]["pop"]*100))}
                                <h2 className="w_t_s_temp">{to_number(data_[0]["main"]["temp"])}°</h2>
                                     </div>

                                     <div className="w_t_s">
                              {Weather_icon(data_[1]["weather"][0]["main"],sorn(data_[1]["dt"],rise,set))}
                                <h2 className="w_t_s_t">{formatTimestampToTime(Filter_tz(data_[1]["dt"],tz))}</h2>
                                {Rain(to_number(data_[1]["pop"]*100))}

                                <h2 className="w_t_s_temp">{to_number(data_[1]["main"]["temp"])}°</h2>
                                     </div>

                                     <div className="w_t_s">
                              {Weather_icon(data_[2]["weather"][0]["main"],sorn(data_[2]["dt"],rise,set))}
                                <h2 className="w_t_s_t">{formatTimestampToTime(Filter_tz(data_[2]["dt"],tz))}</h2>
                                {Rain(to_number(data_[2]["pop"]*100))}

                                <h2 className="w_t_s_temp">{to_number(data_[2]["main"]["temp"])}°</h2>
                                     </div>


                                     <div className="w_t_s">
                              {Weather_icon(data_[3]["weather"][0]["main"],sorn(data_[3]["dt"],rise,set))}
                                <h2 className="w_t_s_t">{formatTimestampToTime(Filter_tz(data_[3]["dt"],tz))}</h2>
                                {Rain(to_number(data_[3]["pop"]*100))}

                                <h2 className="w_t_s_temp">{to_number(data_[3]["main"]["temp"])}°</h2>
                                     </div>
                            
                             </div>








                               <h2 style={{color:"black", textAlign:"center",margin:"0"}}>Weather weekly</h2>

                               <div id="w_t">
                              <div className="w_t_s">
                              {Weather_icon(data_[week[0]]["weather"][0]["main"],sorn(data_[week[0]]["dt"],rise,set))}
                                <h2 className="w_t_s_t">{formatweek(data_[week[0]]["dt"])}</h2>
                                {Rain(to_number(data_[week[0]]["pop"]*100))}

                                <h2 className="w_t_s_temp">{to_number(data_[week[0]]["main"]["temp"])}°</h2>
                                     </div>



                                     <div className="w_t_s">
                              {Weather_icon(data_[week[1]]["weather"][0]["main"],sorn(data_[week[1]]["dt"],rise,set))}

                                <h2 className="w_t_s_t">{formatweek(data_[week[1]]["dt"])}</h2>
                                {Rain(to_number(data_[week[1]]["pop"]*100))}

                                <h2 className="w_t_s_temp">{to_number(data_[week[1]]["main"]["temp"])}°</h2>
                                     </div>



                                     <div className="w_t_s">
                              {Weather_icon(data_[week[2]]["weather"][0]["main"],sorn(data_[week[2]]["dt"],rise,set))}
                                <h2 className="w_t_s_t">{formatweek(data_[week[2]]["dt"])}</h2>
                                {Rain(to_number(data_[week[2]]["pop"]*100))}

                                <h2 className="w_t_s_temp">{to_number(data_[week[2]]["main"]["temp"])}°</h2>
                                     </div>


                                     <div className="w_t_s">
                              {Weather_icon(data_[week[3]]["weather"][0]["main"],sorn(data_[week[3]]["dt"],rise,set))}
                                <h2 className="w_t_s_t">{formatweek(data_[week[3]]["dt"])}</h2>
                                {Rain(to_number(data_[week[3]]["pop"]*100))}

                                <h2 className="w_t_s_temp">{to_number(data_[week[3]]["main"]["temp"])}°</h2>
                                     </div>
                             </div>

                          </div>
                         
                      </div>

                      <div id="r_one_l">
                        <div id="w_n_t">
                        {Weather_icon(data_[0]["weather"][0]["main"],sorn(data_[0]["dt"],rise,set))}
                        <h1 id="w_ty">{data_[0]["weather"][0]["description"]}</h1>
                        </div>
                      

                        <div id="other_we">
                          <div id="sun_rise">
                            <img src={sunrise_img} ></img>
                            <h4>Sunrise</h4>
                            <h3 >{formatTimestampToTime(rise)}</h3>
                          </div>
                          <div id="sun_set">
                          <img src={sunset_img} ></img>
                            <h4>Sunset</h4>
                            <h3>{formatTimestampToTime(set)}</h3>
                          </div>
                          

                        </div>
                      </div>
                    </div>
                   

                </div>
            </div>
        
        </div>


        <div id="botm">

           <div>
              <h1>Aioonc</h1>
           </div>
            <div id="botm-left">

             <h4>Aioonc ©2024  All rights reserved.</h4>
            </div>

           <div id="botm-right">

             <a onClick={()=>{redr(2)}}>Terms of Service</a>
          </div>
       </div>
    </div>):(<div style={{textAlign:"center" , backgroundColor:"white" , height:"100vh"}}>

<img src={spin} style={{width : "100px"}}></img>
<h1>Getting Your experience ready</h1>
</div>);



}



export default Home;



